.main {
    position: relative;
    top: 40px;
    .localize {
        padding: 10px;
        margin: 0 20px 20px 0;
        float: right;
        border: 1px solid #000;
        border-radius: 5px;
        color:rgb(26, 23, 23);
        font-weight: bold;
        cursor: pointer;
    }
    .ua {
        background: linear-gradient(0deg, rgba(255,255,0,1) 0%, rgba(0,0,255,1) 100%);
    }
    .pl {
        background: linear-gradient(0deg, rgb(221, 6, 6) 0%, white 60%);
    }
    .disable {
        background-color: gray;
        cursor: progress;
    }
}
