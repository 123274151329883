.main {
    width: 1360px;
    
    margin: 0 auto;
    background-color: black;
    p {
        color: white;
        font-size: 20px;
        text-align: center;
        padding: 10px 20px 5px 20px;
    }
    @media (max-width: 1360px) {
        width: 90%;
    }
    @media (max-width: 850px) {
        width: 95%;
    }
}
