.header {
    width: 1360px;
    background-color: #fff;
    background: url(../../images/bg3.jpg) top left no-repeat;
    background-size: auto 100%;
    margin: 0 auto 0 auto;
    display: grid;
    grid-template-columns: 1fr;
    border-bottom: 2px solid #000;
    a {
        display: block;
        width: 115px;
        margin: 5px auto 0 auto;
    }
    h1 {
        font-size: 40px;
        display: block;
        padding: 0px 0 0 0;
        color: #2a2a2a;
        padding: 0 20px;
    }
    h2 {
        color: red;
        padding: 0 20px
    }
    @media (max-width: 1360px) {
            width: 90%;
    }
    @media (max-width: 850px) {
        width: 95%;
        grid-template-columns: 1fr;
        height: auto;
        & a {
            width: auto;
            margin: 15px auto 0 auto;
        }
    }
    @media (max-width: 520px) {
        & h1 {
            font-size: 26px;
        }
    }
}